import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

const SecondPage = () => (
    <Layout>
        <h1>Privacy Policy and Terms of Service</h1>
        <p>
            Misfitcoders Privacy Policy Version 2.2 Last Modified: 08/06/2020
            Misfitcoders (“Misfitcoders,” “we,” “us” or “our”) operates several
            mobile applications (hereinafter referred to as the “Service” or
            “Application”). This Privacy Policy informs you of our policies
            regarding the collection, use and disclosure of personal data when
            you use our Service and the choices you have associated with that
            data. We use your data to provide and improve the Service. By using
            the Service, you agree to the collection and use of information in
            accordance with this policy. Unless otherwise defined in this
            Privacy Policy, the terms used in this Privacy Policy have the same
            meanings as in our Terms of Service.
        </p>
        <h2>Definitions</h2>
        <p>
            · Service Service is the mobile application operated by Misfitcoders
            · Personal Data Personal Data means data about a living individual
            who can be identified from those data (or from those and other
            information either in our possession or likely to come into our
            possession). · Usage Data Usage Data is data collected automatically
            either generated by the use of the Service or from the Service
            infrastructure itself. · Cookies are small files stored on your
            device (computer or mobile device). · Data Controller Data
            Controller means the natural or legal person who (either alone or
            jointly or in common with other persons) determines the purposes for
            which and the manner in which any personal information are, or are
            to be, processed. For the purpose of this Privacy Policy, we are a
            Data Controller of your Personal Data. · Data Processors (Service
            Providers or Third Party) Data Processor (or Service Provider or
            Third Party) means any natural or legal person who processes the
            data on behalf of the Data Controller. We may use the services of
            various Service Providers in order to process your data more
            effectively. · Data Subject (or User) Data Subject is any living
            individual who is using our Service and is the subject of Personal
            Data.
        </p>
        <h2>User Consent </h2>
        <p>
            By using our Application, you are consenting to our processing of
            your Personal Data, Usage Data, De-identified Data, and Anonymous
            Data as set forth in this Privacy Policy now and as amended by us.
            Processing means using data collected on a computer/hand held device
            or using or touching information in any way, including but not
            limited to, collecting, storing, deleting, using, combining and
            disclosing information, all of which activities will take place in
            the United States.{' '}
        </p>
        <p>
            Types of Data We Collect · “Personal Data” Data that allows someone
            to identify or contact you, including, for example, your name,
            address, telephone number, email address, as well as any other
            non-public information about you that is associated with or linked
            to any of the foregoing data. · “Anonymous Data” Data that is not
            associated with or linked to your Personal Data. Anonymous Data does
            not, by itself, permit the identification of individual persons. ·
            “De-identified Data” Data that include or encompasses genetic data
            and cannot be completely anonymous. · “Usage Data” When you access
            the Service with a mobile device, we may collect certain information
            automatically, including, but not limited to, the type of mobile
            device you use, your mobile device unique ID, the IP address of your
            mobile device, your mobile operating system, the type of mobile
            Internet browser you use, unique device identifiers and other
            diagnostic data. We may use and store information about your
            location if you give us permission to do so. We use this data to
            provide features of our Service, to improve and customize our
            Service. You can enable or disable location services when you use
            our Service at any time by way of your device settings. We use
            cookies and similar tracking technologies to track the activity on
            our Service and we hold certain information. Cookies are files with
            a small amount of data which may include an anonymous unique
            identifier. Cookies are sent to your browser from a website and
            stored on your device. Other tracking technologies are also used
            such as beacons, tags, and scripts to collect and track information
            and to improve and analyze our Service. You can instruct your
            browser to refuse all cookies or to indicate when a cookie is being
            sent. However, if you do not accept cookies, you may not be able to
            use some portions of our Service. Examples of Cookies we use:
            Session Cookies. We use Session Cookies to operate our Service.
            Preference Cookies. We use Preference Cookies to remember your
            preferences and various settings. “Security Cookies” We use Security
            Cookies for security purposes. Advertising Cookies. Advertising
            Cookies are used to serve you with advertisements that may be
            relevant to you and your interests.
        </p>
        <h2>A Note About Children </h2>
        <p>
            We do not use the Application to knowingly solicit data from or
            market to children under the age of 13 or under the age of 18 for
            Misfitcoders product features that integrate the Helix platform. If
            a parent or guardian becomes aware that his or her child has
            provided us with information without their consent, he or she should
            contact us at{' '}
            <a
                href="mailto:support@misfitcoders.com"
                target="_blank"
                rel="noreferrer"
                class="url"
            >
                support@misfitcoders.com
            </a>
            . We will delete such information from our files within a reasonable
            time.
        </p>
        <h2>
            Legal Basis for Processing Personal Data under the General Data
            Protection Regulation (GDPR)
        </h2>
        <p>
            Your Personal Data may be processed in the country in which it was
            collected and in other countries, including the United States, where
            laws regarding processing of Personal Data may be less stringent
            than the laws in your country.
        </p>
        <p>
            If you are from the European Economic Area (EEA), Misfitcoders legal
            basis for collecting and using the personal information described in
            this Privacy Policy depends on the Personal Data we collect and the
            specific context in which we collect it. Misfitcoders may process
            your Personal Data because: · We need to perform a contract with you
            · You have given us permission to do so · The processing is in our
            legitimate interests and it is not overridden by your rights · For
            payment processing purposes · To comply with the law
        </p>
        <h2>User Registration</h2>
        <p>
            Each Application obtains information that you provide when you
            download and register the Application. When you register with us
            and/or use an Application, you generally provide (a) your name,
            email address, and other registration information; (b) your age and
            sex; (c) information you provide us when you contact us for help;
            and (d) information that is entered into our system when you use the
            Applications, such as your Usage data or other Personal data (“User
            Provided Data”). We may also use the information that you provide to
            us to contact you from time to time to provide you with important
            information, required notices, and marketing promotions. Important
            information can range from changes in our terms of service to
            changes that may affect your data. Users can opt-out of marketing
            promotions.
        </p>
        <p>
            Automatically Collected Data In addition, the Application may
            collect certain information automatically, such as the type of
            mobile device you use, your mobile device’s unique device ID, the IP
            address of your mobile device, your mobile operating system, the
            type of mobile Internet browsers you use, and information about the
            way you use the Application. The Application does collect precise
            information about the location of your mobile device. We may also
            use Cookies and navigational data like Uniform Resource Locators
            (“URL(s)”) to gather information regarding the date and time of your
            visit and the solutions and information for which you searched and
            which you viewed. “Cookies” are small pieces of information that a
            website sends to your smartphone while you are viewing a website. We
            may use both session Cookies (which expire once you close your
            browser) and persistent Cookies (which stay on your smart phone
            until you delete them) to provide you with a more personal and
            interactive experience on our Sites. If you choose to disable
            Cookies, our Applications may not work properly. Like most
            companies, we automatically gather the information described above
            (“Automatically Collected Data”) and store it in log files each time
            you use the Applications. Information Collected from Third Party
            Companies. We may receive Personal and/or Anonymous Data about you
            from companies that provide our Applications by way of a co-branded
            or private-labeled website or companies that advertise their
            products and/or services through our Applications. We may add this
            information to the information we have already collected from you.
            Information Collected from Social Networking Sites. Our Applications
            may collect Personal and/or Anonymous Data about you from a social
            networking site (“SNS”), in accordance with the terms of use and
            privacy policy of the third-party provider of such SNS, if you log
            on with your login credentials from the SNS. We may add this
            information to the information we have already collected from you.
        </p>
        <h2>Use of Your Personal Data</h2>
        <p>
            General Use. In general, Personal Data you submit to us is used
            either to respond to requests that you make, or to aid us in serving
            you better. Data taken from Apple Healthkit is never sold nor shared
            with 3rd parties or used for advertising purposes. Ways we use your
            Personal Data include, but is not limited to: (1) to facilitate the
            creation of and secure your Account on our network; (2) identify you
            as a user in our system; (3) improve the quality of experience when
            you use our Applications; (4) send you a welcome email to verify
            ownership of the email address provided when your Account was
            created; (5) send you a welcome email to verify ownership of the
            email address provided when your Account was created; (6) send you
            administrative email notifications, such as security or support and
            maintenance advisories; and (7) to send newsletters, surveys,
            offers, and other promotional materials related to our Applications
            and for other marketing purposes of Misfitcoders.
        </p>
        <p>
            Creation of Anonymous Data. We may create Anonymous Data records
            from Personal Data by excluding information (such as your name) that
            make the data personally identifiable to you. We use this Anonymous
            Data to analyze request and usage patterns so that we may enhance
            the content of our Applications. Misfitcoders reserves the right to
            use Anonymous Data for any purpose and disclose Anonymous Data to
            third parties, including but not limited to our research partners,
            in its sole discretion. This does not include genetic data.
        </p>
        <h2>Disclosure of Your Personal Data</h2>
        <p>
            We disclose your Personal Data as described below and as described
            elsewhere in this Privacy Policy.
        </p>
        <p>
            · Third Party Service Providers. We may share your Personal Data and
            Usage Data with third party service providers who work on our
            behalf. See Service provider section for more details.{' '}
        </p>
        <p>
            · Affiliates and Acquisitions. We may share some or all of your
            Personal Data with our parent company, subsidiaries, joint ventures,
            or other companies under a common control (“Affiliates”), in which
            case we will require our Affiliates to honor this Privacy Policy. If
            another company acquires our company, business, or our assets, that
            company will possess the Personal Data collected by us and will
            assume the rights and obligations regarding your Personal Data as
            described in this Privacy Policy. In the unlikely event of an
            insolvency, bankruptcy or receivership, your Personal Data may also
            be transferred as a business asset, during which the Privacy Policy
            will still apply.
        </p>
        <p>
            · Other Disclosures. Regardless of any choices you make regarding
            your Personal Data (as described below), Misfitcoders may disclose
            Personal Data if it believes in good faith that such disclosure is
            necessary (a) in connection with any legal investigation; (b) to
            comply with relevant laws or to respond to subpoenas or warrants
            served on Misfitcoders; (c) to protect or defend the rights or
            property of Misfitcoders or users of the Services; and/or (d) to
            investigate or assist in preventing any violation or potential
            violation of the law, this Privacy Policy, or any applicable Terms
            of Use. Users will be notified when Misfitcoders is legally able to.
        </p>
        <h2>Third Party Data Collection – Service Providers</h2>
        <p>
            We may employ third party companies and individuals to facilitate
            our Service (&quot;Service Providers&quot;), provide the Service on
            our behalf, perform Service-related services or assist us in
            analyzing how our Service is used.
        </p>
        <h3>Analytics</h3>
        <p>
            We may use third-party Service Providers to monitor and analyze the
            use of our Service. · Google Analytics Google Analytics is a web
            analytics service offered by Google that tracks and reports website
            traffic. Google uses the data collected to track and monitor the use
            of our Service. This data is shared with other Google services.
            Google may use the collected data to contextualize and personalize
            the ads of its own advertising network. You may opt-out of certain
            Google Analytics features through your mobile device settings, such
            as your device advertising settings or by following the instructions
            provided by Google in their Privacy Policy:{' '}
            <a href="https://policies.google.com/privacy?hl=en">
                https://policies.google.com/privacy?hl=en
            </a>
            For more information on the privacy practices of Google, please
            visit the Google Privacy &amp; Terms web page:{' '}
            <a
                href="https://policies.google.com/privacy?hl=en"
                target="_blank"
                rel="noreferrer"
                class="url"
            >
                https://policies.google.com/privacy?hl=en
            </a>
            · Firebase Firebase is an analytics service provided by Google Inc.
            You may opt-out of certain Firebase features through your mobile
            device settings, such as your device advertising settings or by
            following the instructions provided by Google in their Privacy
            Policy:{' '}
            <a
                href="https://policies.google.com/privacy?hl=en"
                target="_blank"
                rel="noreferrer"
                class="url"
            >
                https://policies.google.com/privacy?hl=en
            </a>
            We also encourage you to review Google&#39;s policy for safeguarding
            your data:{' '}
            <a
                href="https://support.google.com/analytics/answer/6004245"
                target="_blank"
                rel="noreferrer"
                class="url"
            >
                https://support.google.com/analytics/answer/6004245
            </a>
            . For more information on what type of information Firebase
            collects, please visit the Google Privacy &amp; Terms web page:{' '}
            <a
                href="https://policies.google.com/privacy?hl=en"
                target="_blank"
                rel="noreferrer"
                class="url"
            >
                https://policies.google.com/privacy?hl=en
            </a>
        </p>
        <h3>Advertising</h3>
        <p>
            We may use third-party Service Providers to show advertisements to
            you to help support and maintain our Service. · Google AdSense &amp;
            DoubleClick Cookie Google, as a third-party vendor, uses cookies to
            serve ads on our Service. Google&#39;s use of the DoubleClick cookie
            enables it and its partners to serve ads to our users based on their
            visit to our Service or other websites on the Internet. You may opt
            out of the use of the DoubleClick Cookie for interest-based
            advertising by visiting the Google Ads Settings web page:{' '}
            <a
                href="http://www.google.com/ads/preferences/"
                target="_blank"
                rel="noreferrer"
                class="url"
            >
                http://www.google.com/ads/preferences/
            </a>
            · AdMob by Google AdMob by Google is provided by Google Inc. You can
            opt-out from the AdMob by Google service by following the
            instructions described by Google:{' '}
            <a
                href="https://support.google.com/ads/answer/2662922?hl=en"
                target="_blank"
                rel="noreferrer"
                class="url"
            >
                https://support.google.com/ads/answer/2662922?hl=en
            </a>
            For more information on how Google uses the collected information,
            please visit the &quot;How Google uses data when you use our
            partners&#39; sites or app&quot; page:{' '}
            <a
                href="http://www.google.com/policies/privacy/partners/"
                target="_blank"
                rel="noreferrer"
                class="url"
            >
                http://www.google.com/policies/privacy/partners/
            </a>{' '}
            or visit the Privacy Policy of Google:{' '}
            <a
                href="http://www.google.com/policies/privacy/"
                target="_blank"
                rel="noreferrer"
                class="url"
            >
                http://www.google.com/policies/privacy/
            </a>
        </p>
        <h3>Behavioral Remarketing</h3>
        <p>
            Misfitcoders uses remarketing services to advertise on third party
            websites to you after you visited our Service. We and our
            third-party vendors use cookies to inform, optimize andserve ads
            based on your past visits to our Service. · Google Ads (AdWords)
            Google Ads (AdWords) remarketing service is provided by Google Inc.
            You can opt-out of Google Analytics for Display Advertising and
            customize the Google Display Network ads by visiting the Google Ads
            Settings page:{' '}
            <a
                href="http://www.google.com/settings/ads"
                target="_blank"
                rel="noreferrer"
                class="url"
            >
                http://www.google.com/settings/ads
            </a>
            Google also recommends installing the Google Analytics Opt-out
            Browser Add-on -{' '}
            <a
                href="https://tools.google.com/dlpage/gaoptout"
                target="_blank"
                rel="noreferrer"
                class="url"
            >
                https://tools.google.com/dlpage/gaoptout
            </a>{' '}
            - for your web browser. Google Analytics Opt-out Browser Add-on
            provides visitors with the ability to prevent their data from being
            collected and used by Google Analytics. For more information on the
            privacy practices of Google, please visit the Google Privacy &amp;
            Terms web page:{' '}
            <a
                href="https://policies.google.com/privacy?hl=en"
                target="_blank"
                rel="noreferrer"
                class="url"
            >
                https://policies.google.com/privacy?hl=en
            </a>
        </p>
        <h3>Payments</h3>
        <p>
            We may provide paid products and/or services within the Service. In
            that case, we use third-party services for payment processing (e.g.
            payment processors). We will not store or collect your payment card
            details. That information is provided directly to our third-party
            payment processors whose use of your personal information is
            governed by their Privacy Policy. These payment processors adhere to
            the standards set by PCI-DSS as managed by the PCI Security
            Standards Council, which is a joint effort of brands like Visa,
            MasterCard, American Express and Discover. PCI-DSS requirements help
            ensure the secure handling of payment information. The payment
            processors we work with are: · Apple Store In-App Payments Their
            Privacy Policy can be viewed at{' '}
            <a
                href="https://www.apple.com/legal/privacy/en-ww/"
                target="_blank"
                rel="noreferrer"
                class="url"
            >
                https://www.apple.com/legal/privacy/en-ww/
            </a>
            · Google Play In-App Payments Their Privacy Policy can be viewed at{' '}
            <a
                href="https://www.google.com/policies/privacy/"
                target="_blank"
                rel="noreferrer"
                class="url"
            >
                https://www.google.com/policies/privacy/
            </a>
            · Stripe Their Privacy Policy can be viewed at{' '}
            <a
                href="https://stripe.com/us/privacy"
                target="_blank"
                rel="noreferrer"
                class="url"
            >
                https://stripe.com/us/privacy
            </a>
            · PayPal / Braintree Their Privacy Policy can be viewed at{' '}
            <a
                href="https://www.paypal.com/webapps/mpp/ua/privacy-full"
                target="_blank"
                rel="noreferrer"
                class="url"
            >
                https://www.paypal.com/webapps/mpp/ua/privacy-full
            </a>
        </p>
        <p>
            Links to Other Sites Our Service may contain links to other sites
            that are not operated by us. If you click a third party link, you
            will be directed to that third party&#39;s site. We strongly advise
            you to review the Privacy Policy of every site you visit. We have no
            control over and assume no responsibility for the content, privacy
            policies or practices of any third-party sites or services.
        </p>
        <h2>Data Retention Policy</h2>
        <p>
            We will retain User Provided Data for as long as you use the
            Applications and for a reasonable time thereafter. If you would like
            us to delete User Provided Data, please contact us at{' '}
            <a
                href="mailto:support@misfitcoders.com"
                target="_blank"
                rel="noreferrer"
                class="url"
            >
                support@misfitcoders.com
            </a>{' '}
            and we will respond within a reasonable time. Please note that some
            or all of the User Provided Data may be required in order for the
            Applications to function properly, and we may be required to retain
            certain information by law. We offer you choices regarding the
            collection, use, and sharing of your Personal Data.
        </p>
        <p>
            · We will periodically send you free newsletters and emails that
            directly promote the use of our Applications. When you receive
            newsletters or promotional communications from us, you may indicate
            a preference to stop receiving further communications from us and
            you will have the opportunity to “opt-out” by following the
            unsubscribe instructions provided in the email you receive or by
            contacting us directly (please see contact information below).
            Unsubscribe instructions will be included in every email. · Despite
            your indicated email preferences, we may send you service related
            communication, including notices of any updates to our Privacy
            Policy.
        </p>
        <p>
            · Changes to Personal Data. You may delete your Personal Data by
            sending a request to{' '}
            <a
                href="mailto:support@misfitcoders.com"
                target="_blank"
                rel="noreferrer"
                class="url"
            >
                support@misfitcoders.com
            </a>{' '}
            stating to delete your account. Please note that we may be required
            to keep this information and not delete it (or to keep this
            information for a certain time, in which case we will comply with
            your deletion request only after we have fulfilled such
            requirements). When we delete any information, it will be deleted
            from the active database, but may remain in our and or service
            provider archives. We may retain your information for fraud
            prevention or other similar purposes.
        </p>
        <h2>Security of Your Personal Data </h2>
        <p>
            Misfitcoders is committed to protecting the security of your
            Personal Data, including your genetic information if you were to use
            Misfitcoders product features that integrate with the Helix
            platform. We use a variety of industry-standard security
            technologies and procedures to help protect your Personal Data from
            unauthorized access, use, or disclosure. For example, we limit
            access to your Personal Data to employees and contractors who need
            to know that information in order to operate, develop or improve our
            Application. Please be aware that, although we endeavor to provide
            reasonable security for information that we process and maintain, no
            security system can prevent all potential security breaches.
            Therefore, while Misfitcoders uses reasonable efforts to protect
            your Personal Data, Misfitcoders cannot guarantee its absolute
            security.
        </p>
        <h2>Contact Information</h2>
        <p>
            Misfitcoders welcomes your comments or questions regarding this
            Privacy Policy. Please email us at{' '}
            <a
                href="mailto:support@misfitcoders.com"
                target="_blank"
                rel="noreferrer"
                class="url"
            >
                support@misfitcoders.com
            </a>{' '}
            or contact us at the following address: Misfitcoders , Calle Eduardo
            Rivas 14 3E 28019, Madrid Spain.
        </p>
        <h2>Changes to This Privacy Policy</h2>
        <p>
            This Privacy Policy is subject to occasional revision, and if we
            make any material changes in the way we use your Personal Data, we
            will notify you by sending you an email to the last email address
            you provided to us and/or by prominently posting notice of the
            changes on our Site and/or deploying an alert within our
            Applications. Please note that at all times you are responsible for
            updating your Personal Data to provide us with your most current
            email address. In the event that the last email address that you
            have provided us is not valid, or for any reason is not capable of
            delivering to you the notice described above, changes will
            nonetheless be effective. In any event, changes to this Privacy
            Policy may affect our use of Personal Data that you provided us
            prior to our notification to you of the changes. If you do not wish
            to permit changes in our use of your Personal Data, you must notify
            us prior to the effective date of the changes that you wish to
            deactivate your Account with us. Continued use of our Applications,
            following notice of such changes shall indicate your acknowledgement
            of such changes and agreement to be bound by the terms and
            conditions of such changes. Please consult our Site regularly for
            any changes to this Privacy Policy.
        </p>
        <p>&nbsp;</p>
        <h1>Terms Of Service</h1>
        <p>Version 1.1 Last Modified: 08/06/2020</p>
        <p>
            Thank you for using our products and services (the “Service/s”). By
            becoming a member or using our Services, you accept and agree to
            these Terms and Conditions (“Terms”). Therefore, it is important
            that you carefully read through these Terms. If you do not agree to
            these Terms, you should not register with Misfitcoders.
        </p>
        <h2>Using our Services</h2>
        <p>
            To use our Services you must follow some simple rules. The Service
            is only available for your private use and is not intended for
            commercial use. Do not misuse the Services, so that either
            Misfitcoders or anyone else is harmed in any way. You may only use
            the Services as permitted by law and these Terms. By using our
            Services, you agree that:
        </p>
        <p>
            All of the personal data provided by you is accurate and up to date.
            You are solely responsible for all activities on your account and
            all the content that is uploaded and/or created under your
            Misfitcoders account (“User Material”).
        </p>
        <p>
            Misfitcoders does not monitor the contents of the Service, but may
            at any time choose, at its sole discretion, to remove User Material
            from the Service and/or your user account and to terminate your
            account and membership.
        </p>
        <p>
            Your membership, including your email and password, with
            Misfitcoders is personal and may not be transferred or used by
            someone else. You are responsible for storing your login details in
            a safe manner. Misfitcoders is not in any way responsible for any
            loss or damage caused by unauthorized access to your account or use
            of your login details. If you learn of or suspect any unauthorized
            use of your account, you must immediately inform Misfitcoders’s
            Customer Service. Contact details are available at the bottom of
            this document.
        </p>
        <p>
            Misfitcoders is not intended for use by persons under the age of 13.
            To use Misfitcoders, you must be at least 13 years old. To use
            Misfitcoders product features that integrate with the Helix
            platform, you must be 18 and over.
        </p>
        <p>
            Violation of any of these Terms will lead to a direct termination of
            your user account and subscription. If you violate these terms, you
            will not get a refund.
        </p>
        <p>
            You may not engage in any commercial activities, advertise and/or
            provide hints (such as links) on where commercial activities are
            present through our Services.
        </p>
        <p>
            You may not contribute with any propaganda, religious and/or
            political views, or contribute with information which in any way
            contains or involves incitement to racial hatred, child pornography
            or pornography through the use of our Services. Further, you may not
            defame, harass or offend other people through the use of our
            Services. If you have any criticism or feedback regarding
            Misfitcoders or our Services, you will first contact Misfitcoders to
            help us to improve our Services. You may not transmit, and/or
            distribute files that may damage Misfitcoders or others’ computers
            or property (such as viruses and trojan horses). You may not share
            others’ personal information, without their approval.
        </p>
        <h2>Your account</h2>
        <p>
            You can at any time choose to cancel/end your account at your
            convenience. Please note that uninstalling the mobile application or
            closing your account will not automatically stop your subscription —
            you must actively cancel the subscription on the Apple App Store or
            Google Play Store. Please note that if you have subscribed to
            Misfitcoders through the use of App Store, Google Play Store, Amazon
            App Store or any other such service provider, you can only cancel
            your subscription through the use of their services. To find out how
            to cancel the subscription and/or your account, please follow the
            instructions in the app or on the website. Special terms and
            conditions apply to Misfitcoders Premium.
        </p>
        <h2>Health and nutritional information</h2>
        <p>
            You are responsible for your own health. Misfitcoders is not a
            medical organization, and we will not provide you with any medical
            advice or diagnosis. The purpose of the Services is solely to help
            our users to record what they eat, how they exercise and to track
            their health, weight and fitness progress. Information made
            available through the Services and by our partners and affiliates
            shall solely be used for recreational and educational purposes.
            Always consult with your doctor before starting a diet or fitness
            program or if you experience any pain or discomfort. Misfitcoders
            cannot guarantee any health, weight and/or fitness results or
            improvements. Our Services are only intended for healthy adults, and
            please do not use our Services if you suffer from, or might suffer
            from, any medical condition that may be impaired by diet or
            exercise. Nutritional information found in our database has not been
            verified, investigated or reviewed by Misfitcoders. Misfitcoders
            cannot guarantee that the nutritional information provided in our
            database is accurate, reliable or complete. Misfitcoders is not
            responsible for any personal injury or any other damages that may
            have been the result, direct or indirect, of any use or misuse of
            the Services.
        </p>
        <h2>Misfitcoders Premium</h2>
        <p>
            This section only applies when you purchase and/or subscribe to
            Misfitcoders Premium or other paid products. By paying the
            subscription fee you get access to Misfitcoders Premium during the
            time your subscription is valid, subject to these Terms. All
            subscriptions with Misfitcoders Premium are paid in advance. You may
            at any time terminate your membership, in which case your membership
            will still be valid for the subscription time you have already paid
            for. The subscription can be cancelled at anytime. Subscription
            automatically renews unless auto-renew is turned off at least 24
            hours before the end of the current period. If you have subscribed
            to Misfitcoders through the use of App Store, Google Play Store,
            Amazon App Store or any other such service provider, using in-app
            purchase, you can only cancel your subscription through the use of
            their services. Subscription fees can be found on
            Misfitcoders’swebsite (
            <a
                href="http://www.misfitcoders.com"
                target="_blank"
                rel="noreferrer"
                class="url"
            >
                www.misfitcoders.com
            </a>
            ) and in the mobile application. Misfitcoders accepts a variety of
            different methods of payment, such as PayPal, Stripe, Apple iTunes,
            Google Play, Amazon App Store and credit card payment. Special terms
            and conditions may apply. Misfitcoders reserves the right to change
            the subscription fees from time to time. You hereby consent to be
            charged immediately upon subscribing with Misfitcoders Premium or
            other paid products. This means that you will only have 14 days to
            withdraw from the contract with Misfitcoders from the date you
            receive confirmation of your subscription via email if you do not
            start using the Services during that period. To use your right of
            withdrawal you must send an email to Misfitcoders. Any prepaid fees
            will be refunded within 30 days. All payments handled by Apple, such
            as in-app purchases, can only be refunded by Apple and with Apple’s
            consent. Default in payment shall not be deemed as a termination of
            an Misfitcoders Premium subscription.
        </p>
        <h2>Intellectual Property</h2>
        <p>
            All rights in and to the Services, including any trademarks, service
            marks, trade names and copyrighted content (collectively
            “Intellectual Property”) presented within the Service are the
            property of Misfitcoders and/or third parties. You agree not to use
            Intellectual Property for any other purposes except for your use of
            the Service, unless required otherwise by applicable mandatory law.
            By submitting User Material to Misfitcoders, you warrant and
            represent that you hold the copyright, trademark and/or other
            intellectual property rights to your content. You agree to grant
            Misfitcoders a non-exclusive, transferable, sub-licensable,
            royalty-free, worldwide license to use User Material to the extent
            necessary for Misfitcoders to operate and maintain the Service. This
            license shall remain valid until the respective User Material is
            deleted from the Service by you or by Misfitcoders in accordance
            with these Terms.
        </p>
        <h2>Disclaimer and limitation of liability</h2>
        <p>
            You use the Service at your own risk. The Service is provided “AS
            IS”, without any warranties, and Misfitcoders does not warrant that
            the Service and availability thereof will be uninterrupted or error
            free. Misfitcoders does not assume any responsibility for errors or
            omissions in the information or software or other documents,
            including User Material, which are referenced by or linked to.
            References or links to third parties&#39; websites are provided
            &quot;AS IS&quot; without warranty of any kind, either express or
            implied. In no event shall Misfitcoders be liable for any indirect
            or consequential damages, except in cases of intentional misconduct
            or gross negligence. To the maximum extent permitted under
            applicable law, Misfitcoders’s aggregated liability for any direct
            damages shall be limited to the lesser of: (i) membership fees paid
            by the user; (ii) membership fees paid by the user during the
            previous calendar year; or (iii) 1 000 USD.
        </p>
        <h2>Miscellaneous</h2>
        <p>
            For the sake of clarity, Misfitcoders doesn’t undertake the
            obligation to monitor the contents of User Material. We allow users
            to see content generated by other users. Sometimes, users may view
            content that we deem as inappropriate.
        </p>
        <p>
            You are not entitled to assign your rights and/or obligations under
            these Terms or use of the Service to any third party without
            Misfitcoders’s prior written consent. Misfitcoders is entitled to
            assign its rights and/or obligations under these Terms.
        </p>
        <p>
            Misfitcoders reserves the right to refuse the Service to anyone for
            any reason at any time. Misfitcoders may revise these Terms from
            time to time and the most current version will always be posted on
            Misfitcoders’s website (
            <a
                href="http://www.misfitcoders.com"
                target="_blank"
                rel="noreferrer"
                class="url"
            >
                www.misfitcoders.com
            </a>
            ). Any and all material changes shall become into effect between you
            and Misfitcoders upon your acceptance of such changes (e.g. by using
            the Services after such notification has been made to you or
            renewing your subscription).
        </p>
        <h2>Governing law and dispute resolution</h2>
        <p>
            These Terms and the use of the Services are governed by the laws of
            Spain, except for its conflicts of laws principles. All claims
            arising out of or relating to these Terms or the Service shall be
            resolved by Spain courts, whereby the Court of Madrid shall be the
            court of first instance.
        </p>
        <h3>Contact details</h3>
        <p>
            For further information, inquiries or assistance you may contact
            Misfitcoders. Customer Service is available in ways specified on the
            website or in the apps. The channels for support might differ from
            time-to-time.
        </p>
        <h2>Contact Information</h2>
        <p>
            Misfitcoders , Calle Eduardo Rivas 14 3E 28019, Madrid Spain
            <a
                href="mailto:support@misfitcoders.com"
                target="_blank"
                rel="noreferrer"
                class="url"
            >
                support@misfitcoders.com
            </a>{' '}
        </p>
    </Layout>
)

export default SecondPage
